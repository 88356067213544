<template>
  <div class="c-app wy-login-page flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <div class="wy-login-page-block-container">
            <CCardGroup>
              <CCard
                  color="white"
                  text-color="white"
                  class="wy-first-card text-center py-5 d-md-down-none"
                  body-wrapper
              >

                  <img v-show="$config.profile.name === 'profile_almashreq'" src="~@/assets/images/profile_almashreq/logo.png" style="width: 240px;max-width: 350px;" alt="">

                  <img v-show="$config.profile.name === 'profile_alhammad'" src="~@/assets/images/profile_alhammad/just_logo_blue.svg" style="width: 220px;max-width: 350px;" alt="">

              </CCard>
              <CCard style="background-color: #FFFFFF;" class="wy-second-card p-2 flex-row align-items-center">
                <CCardBody>
                  <router-link class="wy-login-page-change-language"
                               :to="{ name: this.$route.name, params: { languageCode: $store.state.languageCode === 'en' ? 'ar' : 'en' } }">
                    <span v-if="$store.state.languageCode === 'en'" class="arabic-font">العربية</span>
                    <span v-if="$store.state.languageCode === 'ar'" class="english-font">English</span>
                  </router-link>
                  <div class="text-center d-lg-none mb-2">
                    <img v-show="$config.profile.name === 'profile_almashreq'" src="~@/assets/images/profile_almashreq/logo.png" style="width: auto;max-width: 240px;" alt="">

                    <img v-show="$config.profile.name === 'profile_alhammad'" src="~@/assets/images/profile_alhammad/just_logo_blue.svg" style="width: auto;max-width: 200px;" alt="">
                  </div>
                  <router-view :key="$route.path"></router-view>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'TheLogin',
}
</script>
